import React, { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from "react-router";
import { BrowserRouter } from "react-router-dom";

const Index = lazy(() => import('./pages/App.js'))
export default () => {
  return (
    <BrowserRouter basename="/lp-app">
      <Switch>
        <Route exact path="/:lang(en-US|ja-JP|zh-TW|ko-KR|zh-CN)?/" component={Index}/>
      </Switch>
    </BrowserRouter>
  );
};
